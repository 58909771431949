$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

.layout {
  display: flex;
  @include fullHeight;
  width: 100%;
  align-items: stretch;
  position: relative;

  > .content {
    margin-left: $navWidth;
    flex: 1;
    display: flex;
    flex-direction: column;

    > .profileBar {
      width: 100%;
      height: 66px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 40px;
      gap: 30px;

      @mixin linkIcon {
        width: 26px;
        height: 26px;
        -webkit-mask: url("../../assets/images/content_layout/profile_bar/link_icon.svg") no-repeat center;
        mask: url("../../assets/images/content_layout/profile_bar/link_icon.svg") no-repeat center;
      }
      @mixin linkStyle {
        font-weight: bold;
        font-size: 16px;
        line-height: 1.6;
        letter-spacing: -0.03em;
        display: flex;
      }

      > .links {
        display: flex;
        gap: 30px;

        > .feelgoodLink {
          > .linkIcon {
            @include linkIcon;
            background-color: $colorFGYellow;
          }

          @include linkStyle;
          color: $colorFGYellow;
        }

        > .kpassLink {
          > .linkIcon {
            @include linkIcon;
            background-color: $colorKPBlue;
          }

          @include linkStyle;
          color: $colorKPBlue;
        }

        > .dcasLink {
          > .linkIcon {
            @include linkIcon;
            background-color: $colorFGBlue;
          }

          @include linkStyle;
          color: $colorFGBlue;
        }
      }

      > .selectorsWrapper {
        display: flex;
        gap: 40px;

        .label {
          color: #646F96;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: -0.48px;

          display: flex;
          justify-content: center;
          align-items: center;
        }

        > .country {

        }

        > .selectors {
          display: flex;
          align-content: center;
          gap: 10px;
        }

        > .myInfo {
          display: flex;
          align-items: center;
          gap: 20px;

          > .info {
            display: flex;
            align-items: center;
            gap: 8px;

            > .roleName {
              font-weight: normal;
              font-size: 16px;
              line-height: 1.6;
              letter-spacing: -0.03em;
              color: $colorBodyTextBlue;
            }

            > .name {
              font-weight: normal;
              font-size: 16px;
              line-height: 1.6;
              letter-spacing: -0.03em;
              color: $colorBodyTextBlue;
              text-decoration: underline;
              cursor: pointer;

              > .accent {
                font-weight: bold;
              }
            }
          }


          > .logoutBtn {
            border: 1px solid $colorBodyTextBlue;
            width: 64px;
            height: 26px;
            border-radius: 100px;
            font-weight: bold;
            font-size: 12px;
            line-height: 1.6;
            letter-spacing: -0.05em;
            color: $colorBodyTextBlue;
          }
        }
      }
    }


    > .main {
      background-color: $colorF6;
      height: 100%;
      padding: {
        top: 40px;
        left: #{"min(6vw, 129px)"};
        right: #{"min(6vw, 129px)"};
        bottom: 182px;
      }

      > .breadcrumb {
        margin-bottom: 20px;
        display: flex;

        > .item {
          font-weight: normal;
          font-size: 14px;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $colorBodyTextBlue;

          &.mainMenu {
            &::after {
              content: "\00a0\00a0>\00a0\00a0";
            }
          }

          &.subMenu {
            font-weight: bold;
          }
        }
      }
    }
  }
}

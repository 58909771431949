$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@import "print";

.infoCover {
  margin-top: 50px;

  padding: 50px 70px;
  position: relative;
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  width: 1160px;
  border-radius: 20px;
  border: 2px solid #E5E5EF;
  background-color: white;


  // 검사결과 안내지 내용
  .content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    padding: 40px 30px;

    // 아이 얼굴 + 제목 영역
    .titleWrapper {

      //width: 960px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      .kidsImg {
        position: absolute;
        right: 60px;
        top: 110px;
        width: 250px;
        height: 250px;
      }
    }

    // 타이틀 - 글자 영역
    .mainWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > .text {
        width: 760px;
        color: #383649;
        font-size: 85px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 110.5px */
        letter-spacing: -2.55px;
      }

      // 추가해야함
      > .foreignText {
        width: 760px;
        color: #383649;
        font-size: 55px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 110.5px */
        letter-spacing: -2.55px;
      }

    }

    .subText {
      width: 80%;
      color: #6b6b6b;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      letter-spacing: -0.84px;

      > .strong {
        color: #383649;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 44.8px */
        letter-spacing: -0.84px;
      }
    }

    // 꼬불선
    > .underline {
      width: 100%;
      height: 11px;
      background-image: url('https://images.feel-good.io/report/wave_underline.svg');
    }

    // 검사결과 안내 내용
    .contentWrapper {
      display: flex;
      width: 960px;
      padding: 0 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 25px;

      .contentBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
      }

      // 헤더영역 (1 K-PASS 검사 결과 내용)
      .contentHeader {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        flex-direction: row;

        > .text {
          width: 873px;

          color: #383649;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 35.2px */
        }

        .numberBox {
          display: flex;
          width: 35px;
          height: 35px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;

          border-radius: 6px;
          background: #383649;

          > .number {
            color: #fff;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 35.2px */
            letter-spacing: -0.66px;
          }
        }
      }

      // 지문 내용(K-PASS 지능검사의 결과지는~...)
      .contents {
        align-self: stretch;

        color: #6b6b6b;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 35.2px */

        // ul, li 부분 스타일
        .firstContentContainer {
          list-style: disc;
          padding-left: 30px;
          margin-bottom: 50px;
          width: 890px;

          .firstContentItem {
            list-style: disc;

            > .strong {
              color: #583be2;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: 160%;
            }

            > .weak {
              color: #6b6b6b;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 160%;
            }
          }

          .firstContentWithButtonContainer {
            display: flex;
            justify-content: space-between;
            padding-right: 10px;
            margin-top: 10px;

          }

          .firstContentShortContainer {

            li{
              width: 700px;
            }

          }

          .strongBold {
            font-weight: 700;
            color: #6b6b6b;
          }
        }


        // 해설 가이드
        .explainContainer {
          user-select: auto !important;
          display: flex;
          position: relative;
          border-radius: 20px;
          border: 3px solid var(--K-PASS-KPblue-500_main, #583be2);
          padding: 8px 12px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;

          > .explanationGuideButton {
            width: 80px;
            height: 80px;
            background-image: url("https://images.feel-good.io/guide/explanationGuideButton.svg");
            background-size: cover;
            transition: background-image 0.3s ease;
          }

          > .explainText {
            color: var(--GrayScale-G-500_subtext, #6b6b6b);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: -0.54px;
            text-align: center;
            white-space: nowrap;
          }
        }


      }

      // 지문 내용2 (K-PASS 검사 만의 특징)
      .contentBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
      }
    }
  }
}


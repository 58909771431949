$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

.fieldLine {
  display: flex;
  gap: 10px;
  //align-items: flex-start;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;

  .addedField {
    margin-left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .inputField {
    @include inputFieldStyle;
    width: 700px;
    padding: 10px 14px;
  }

  .numberInputWrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    width: 123px;
    height: 46px;

    .numberInput {
      @include inputFieldStyle;
      width: 100px;
      padding: 10px 14px;
    }
  }


  .textAreaField {
    @include textAreaStyle(100%, 46px);
    width: 700px;
    padding: 10px 14px;
  }

  .halfInputField {
    @include inputFieldStyle;
    width: 300px;
    padding: 10px 14px;

  }

  .checkboxLabel {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  &.tableLine {
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;

    > .tableArea {
      flex: 1;

      > .textInput {
        @include searchFieldStyle;
        width: fit-content;

        .searchWordInput {
          width: 360px;
        }
      }

      > .list {
        margin-top: 10px;
        border: 1px solid $colorDE;

        $width: 1058px;
        $height: 254px;

        max-width: $width;

        .listTable {
          width: calc(100% + 2px);

          .listTableBody {
            height: $height;
          }

          .noResultRow {
            > td {
              width: $width;
              height: calc(#{$height} - 1px);
            }
          }
        }

        &.notKO {
          $width: 1218px;
          width: $width;

          .listTable {
            width: $width;

            .listTableBody {
              height: $height;
            }

            .noResultRow {
              > td {
                width: $width;
                height: calc(#{$height} - 1px);
              }
            }
          }
        }
      }

      .selectedItem {
        max-width: 1058px;
        margin-top: 30px;

        &.notKO {
          width: 1218px;
        }

        > .title {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 14px;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $colorFGDarkBlue;
        }

        > .list {
          > .noItem {
            display: block;
            margin-bottom: 10px;
            width: 100%;
            border: 1px solid $colorDE;
            padding: 22px 0;
            text-align: center;
          }
        }
      }
    }
  }
}

.availableDaysField {
  &.notKO {
    width: 150px !important;
  }
}
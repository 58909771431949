$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

.memoArea {
  display: flex;
  gap: 20px;

  > .panelWrap {
    width: 50%;

    > .memoList {
      > .list {
        > .noDataItem {
          border: 1px solid $colorDE;
          font-weight: normal;
          font-size: 14px;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $color75;
          padding: 29px 100px;
          text-align: center;
        }

        > .item {
          display: flex;
          flex-direction: column;
          padding: 12px 20px;
          border: 1px solid $colorDE;

          &:not(:last-child) {
            border-bottom: 0;
          }

          > .contentArea {
            display: flex;
            gap: 10px;
            justify-content: space-between;
            align-items: center;

            > .writerInfo {
              display: flex;
              gap: 4px;

              font-weight: normal;
              font-size: 12px;
              line-height: 1.6;
              letter-spacing: -0.03em;
              color: $colorBodyTextBlue;

              > .dateTime {
              }

              > .writer {
              }
            }

            > .deleteButtonArea {
              margin-right: -8px;
              flex-shrink: 0;
              display: flex;
              gap: 4px;

              > .deleteBtn {
                padding: 1px 6px;
                border: 1px solid $colorBodyTextBlue;
                border-radius: 2px;
                font-weight: normal;
                font-size: 14px;
                line-height: 1.6;
                letter-spacing: -0.03em;
                color: $colorBodyTextBlue;
              }
            }
          }

          > .content {
            margin-top: 12px;
            font-size: 14px;
            line-height: 1.6;
            letter-spacing: -0.03em;
            color: $color75;
            display: flex;

            > .type {
              margin-right: 6px;
              font-weight: bold;
              flex-shrink: 0;
            }

            > .contentText {
              white-space: pre-wrap;
            }
          }

        }
      }

      > .pagingWrapper {
        margin-top: 20px;
      }
    }

    > .memoInputField {
      height: fit-content;
    }

    > .reviewInputField {
      height: fit-content;
      padding: 20px 30px;
      gap: 20px;

      color: var(--GrayScale-G2-757575, #757575);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      letter-spacing: -0.42px;

      .reviewStarArea {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .star {
          width: 50px;
          height: 50px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
          background-image: url("../../../assets/images/review_star_point.svg");
        }
      }

      .reviewContentArea{
        display: flex;
        width: 100%;
        padding: 20px 30px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);
        background: var(--GrayScale-W-FFFFFF, #FFF);

        .text{
          color: var(--GrayScale-G-700_bodytext, #404040);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          letter-spacing: -0.42px;
        }
      }

    }


  }
}

.typeRadios {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;

  > .radioLabel {
    display: flex;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.03em;
    color: $color75;
    align-items: center;

    > .radio {
      margin: 4.9px;
    }
  }
}

@mixin memoTextArea {
  margin-right: 10px;
  flex: 1;
  height: 80px;

  > textarea {
    @include textAreaStyle(100%, 100%);
  }
}

.inputArea {
  display: flex;

  > .textArea {
    @include memoTextArea;
  }

  > .saveBtn {
    @include secondaryButtonStyle;
    width: 80px;
    height: 80px;
  }
}

.memoEditForm {
  width: 100%;
  display: flex;

  > .textArea {
    @include memoTextArea;
    margin-right: -8px;
  }
}
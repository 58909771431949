$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@import '../variables';
@import "print";

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  page-break-before: always;
  box-sizing: border-box;
  width: 1160px;
  border: 2px solid #E5E5EF;
  border-radius: 20px;
  padding: 50px 70px;
  background-color: white;
  margin-top: 50px;
}

.content {
  padding: 40px 30px;
  display: flex;
  /*width: 960px;*/
  align-items: flex-start;
  align-content: flex-start;
  gap: 40px;
  flex-wrap: wrap;

  .section {
    .summaryWrapper {
      display: flex;
      flex-direction: column;
      gap: 50px;

      .title {
        display: flex;
        flex-direction: column;
        gap: 20px;

        h2 {
          font-size: 85px;
          font-weight: bold;
          line-height: 1.3;
          letter-spacing: -2.55px;
          color: $c38;

          display: flex;
          flex-direction: column;

          > .titleText {
            display: flex;
            flex-direction: column;
          }

          > .number {
            color: $cCD;
            font-weight: normal;
            display: flex;

            > .strong {
              color: $c38;
              font-weight: bold;
            }

            > .explainContainer {
              user-select: auto !important;
              display: flex;
              position: relative;
              cursor: pointer;
              width: 145px;
              border-radius: 20px;
              padding: 8px 12px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 5px;
              left: 750px;

              > .explanationGuideButton {
                width: 80px;
                height: 80px;
                background-image: url('https://images.feel-good.io/guide/explanationGuideButton.svg');
                background-size: cover;
                transition: background-image 0.3s ease;
              }

              > .explanationGuideButton:hover {
                background-image: url('https://images.feel-good.io/guide/explanationGuideButtonHover.svg'); /* 마우스 오버 시 변경할 이미지 경로 */
              }

              > .explainText {
                color: var(--GrayScale-G-500_subtext, #6b6b6b);

                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%; /* 25.2px */
                letter-spacing: -0.54px;

                white-space: nowrap;
              }
            }
          }
        }

        .subtitle {
          font-size: 28px;
          font-weight: normal;
          line-height: 1.6;
          letter-spacing: -0.84px;
          color: $c6B;

          .strong {
            color: $c38;
            font-weight: bold;
          }
        }
      }

      .underline {
        width: 100%;
        height: 11px;
        background-image: url('https://images.feel-good.io/report/wave_underline.svg');
      }

      .table {
        th {
          height: 100px;
        }

        td {
          height: 30px;
        }

        .tdStyle {
          height: 60px;
        }
      }
    }

    .above12-ch03-chart-wrapper {
      margin-bottom: -20px;

      display: flex;
      flex-direction: column;
      gap: 30px;

      font-size: 36px;

      > .chart {
        padding: 50px 0 20px 0;

        display: flex;
        gap: 20px;
        justify-content: center;

        > .sign {
          padding-top: 205px;
          width: 72px;

          display: flex;
          flex-direction: column;
          gap: 10px;

          > .ds-text {
            font-size: 18px;
            font-weight: normal;
            line-height: 1.4;
            letter-spacing: -0.03em;
          }
        }

        > .a-chart {
          display: flex;
          flex-direction: column;
          gap: 20px;

          > .a-chart-title {
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: -0.03em;
            color: $kpNavy500Main;

            display: flex;
            flex-direction: column;

            > .light {
              font-size: 18px;
              font-weight: normal;
              line-height: 1.4;
            }
          }

          > .circle {
            width: 300px;
            height: 300px;
            border-radius: 50%;
            overflow: hidden;
            position: relative;

            > .ability {
              width: 100%;
              height: 100%;
              position: absolute;
              bottom: 0;
              left: 0;
            }

            &.successive {
              background-color: $kpBlue000Bg;

              > .ability {
                background-color: $c58;
              }
            }

            &.simultaneous {
              background-color: #fff3f6;

              > .ability {
                background-color: #ff7b9a;
              }
            }
          }
        }
      }

      > .ds {
        text-align: right;
        font-size: 18px;
        font-weight: normal;
        line-height: 1.4;
        letter-spacing: -0.03em;
        color: $kpNavy500Main;
      }
    }

    .mainWrapper {
      margin-top: 50px;
      gap: 30px;

      &.above12 {
        margin-top: 30px;
      }

      &.above12Chapter3Wrapper {
        margin-top: 0;
      }

      &.above12Chapter4Wrapper {
        margin-top: 0;
        gap: 50px;

        .imageWrapper {
          width: 760px;
          height: 600px;
          align-self: center;

          > img {
            width: 100%;
          }
        }

        > .result {
          gap: 50px;
        }
      }

      display: flex;
      flex-direction: column;
      gap: 30px;

      $tipWrapperDefaultHeight: 160px;

      $tipTextBoxDefaultHeight: 86px;
      $tipTextBoxDefaultWidth: 257px;
      $tipTextBoxTopSpaceDefaultHeight: calc((#{$tipWrapperDefaultHeight} - #{$tipTextBoxDefaultHeight}) / 2);
      $tipTextDefaultHeight: 35px;
      $tipTextDefaultWidth: 149px;
      $tipDividerAndAnswerDefaultWidth: 0;
      $tipDividerAndAnswerDefaultHeight: 0;

      $tipTextBoxDefaultHeightNotKO: 86px;
      $tipTextBoxDefaultWidthNotKO: 335px;
      $tipTextBoxTopSpaceDefaultHeightNotKO: calc((#{$tipWrapperDefaultHeight} - #{$tipTextBoxDefaultHeightNotKO}) / 2);
      $tipTextDefaultHeightNotKO: 35px;
      $tipTextDefaultWidthNotKO: 227px;

      $tipWrapperExpandedHeight: 260px;

      $tipTextBoxExpandedHeight: 208px;
      $tipTextBoxExpandedWidth: 100%;
      $tipTextBoxTopSpaceExpandedHeight: calc((#{$tipWrapperExpandedHeight} - #{$tipTextBoxExpandedHeight}) / 2);
      $tipTextExpandedHeight: 160px;
      $tipTextExpandedWidth: 632px;
      $tipDividerAndAnswerExpandedMarginTop: 10px;
      $tipDividerAndAnswerExpandedHeight: calc(#{$tipTextExpandedHeight} - #{$tipTextDefaultHeight} - #{$tipDividerAndAnswerExpandedMarginTop});

      $tipTextBoxExpandedMarginBottomNotKO: 20px;
      $tipTextBoxExpandedHeightNotKO: 243px;
      $tipTextBoxExpandedTotalHeightNotKO: calc(#{$tipTextBoxExpandedHeightNotKO} + #{$tipTextBoxExpandedMarginBottomNotKO});
      $tipTextBoxExpandedWidthNotKO: 100%;
      $tipTextBoxTopSpaceExpandedHeightNotKO: 0;
      $tipTextExpandedHeightNotKO: 195px;
      $tipTextExpandedWidthNotKO: 632px;
      $tipDividerAndAnswerExpandedHeightNotKO: calc(#{$tipTextExpandedHeightNotKO} - #{$tipTextDefaultHeight} - #{$tipDividerAndAnswerExpandedMarginTop});

      .darkmongTip {
        .darkmongSpeechWrapper {
          margin-bottom: -30px;
          width: 100%;
          height: $tipWrapperDefaultHeight;

          overflow: hidden;

          flex-direction: column;
          display: flex;

          transition: $darkmongBoxTransitionValue;

          > .darkmongImageAndTextWrapper {
            display: flex;
            gap: 20px;
            align-items: flex-start;
            height: $tipWrapperDefaultHeight;

            > .darkmongImgBox {
              flex-shrink: 0;

              width: 180px;
              height: 100%;

              > img {
                width: 100%;
              }
            }

            > .textBoxWrapper {
              width: 100%;
              display: flex;
              flex-direction: column;

              > .topSpace {
                width: 100%;
                height: $tipTextBoxTopSpaceDefaultHeight;
                transition: $darkmongBoxTransitionValue;
              }

              > .textBox {
                box-sizing: border-box;
                position: relative;
                transition: $darkmongBoxTransitionValue;

                width: $tipTextBoxDefaultWidth;
                height: $tipTextBoxDefaultHeight;

                cursor: pointer;
                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                border-radius: 20px;
                background: linear-gradient(180deg, #583be2 0%, #25179f 100%);
                padding: 24px 30px;
                overflow: hidden;

                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                $animationDuration: 1.5s;
                --translateX-value: 400px;

                &:before {
                  content: '';
                  display: block;
                  position: absolute;
                  background: rgba(255, 255, 255, 0.7);
                  width: 20px;
                  height: 120%;
                  left: 0;
                  top: -10%;
                  filter: blur(20px);

                  animation-name: shiningButtonBefore;
                  animation-duration: $animationDuration;
                  animation-iteration-count: infinite;
                }

                &:after {
                  content: '';
                  display: block;
                  position: absolute;
                  background: rgba(255, 255, 255, 0.5);
                  width: 10px;
                  height: 120%;
                  left: 30px;
                  top: -10%;
                  filter: blur(10px);

                  animation-name: shiningButtonAfter;
                  animation-duration: $animationDuration;
                  animation-iteration-count: infinite;
                }

                > .text {
                  font-size: 22px;
                  font-weight: bold;
                  line-height: 1.6;
                  letter-spacing: -0.03em;
                  color: white;

                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;

                  overflow: hidden;
                  width: $tipTextDefaultWidth;
                  height: $tipTextDefaultHeight;

                  > .question {
                    > .yellow {
                      color: #ffe606;
                    }
                  }

                  > .divider-and-answer {
                    transition: $darkmongBoxTransitionValue;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    width: $tipDividerAndAnswerDefaultWidth;
                    height: $tipDividerAndAnswerDefaultHeight;

                    overflow: hidden;
                  }
                }

                > .next-button {
                  align-self: center;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  width: 38px;
                  height: 40px;
                  padding: 0;
                  cursor: pointer;
                }

                > .close-button {
                  display: none;
                  cursor: pointer;
                }
              }
            }
          }

          &.notKO {
            > .darkmong-image-and-text-wrapper {
              > .darkmong-img-box {
              }

              > .text-box-wrapper {
                > .top-space {
                  height: $tipTextBoxTopSpaceDefaultHeightNotKO;
                }

                > .text-box {
                  width: $tipTextBoxDefaultWidthNotKO;

                  > .text {
                    width: $tipTextDefaultWidthNotKO;
                    height: $tipTextDefaultHeightNotKO;

                    > .question {
                      > .yellow {
                      }
                    }

                    > .divider-and-answer {
                    }
                  }

                  > .next-button {
                  }

                  > .close-button {
                  }
                }
              }
            }
          }

          &.expanded {
            width: 100%;
            height: $tipWrapperExpandedHeight;

            > .darkmong-image-and-text-wrapper {
              height: fit-content;

              > .darkmong-img-box {
                height: $tipWrapperExpandedHeight;
              }

              > .text-box-wrapper {
                > .top-space {
                  height: $tipTextBoxTopSpaceExpandedHeight;
                }

                > .text-box {
                  cursor: default;
                  box-sizing: border-box;
                  width: $tipTextBoxExpandedWidth;
                  height: $tipTextBoxExpandedHeight;
                  justify-content: space-between;

                  &:before {
                    display: none;
                  }

                  &:after {
                    display: none;
                  }

                  > .text {
                    width: $tipTextExpandedWidth;
                    height: $tipTextExpandedHeight;

                    > .divider-and-answer {
                      margin-top: $tipDividerAndAnswerExpandedMarginTop;
                      height: $tipDividerAndAnswerExpandedHeight;
                      width: 100%;
                      display: flex;
                      transition: $darkmongBoxTransitionValue;
                      opacity: 1;

                      > .divider {
                        width: 100%;
                        border-top: 1px dashed #d6ceff;
                      }

                      > .answer {
                        font-weight: normal;
                        color: #d6ceff;

                        > .strong {
                          font-weight: bold;
                          color: white;
                        }
                      }
                    }
                  }

                  > .next-button {
                    display: none;
                  }

                  > .close-button {
                    align-self: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    padding: 20px 0;

                    > img {
                      width: 38px;
                      height: 40px;
                    }
                  }
                }
              }
            }

            &.notKO {
              height: $tipTextBoxExpandedTotalHeightNotKO;

              > .darkmong-image-and-text-wrapper {
                > .darkmong-img-box {
                }

                > .text-box-wrapper {
                  > .top-space {
                    height: $tipTextBoxTopSpaceExpandedHeightNotKO;
                  }

                  > .text-box {
                    margin-bottom: $tipTextBoxExpandedMarginBottomNotKO;
                    height: $tipTextBoxExpandedHeightNotKO;

                    > .text {
                      height: $tipTextExpandedHeightNotKO;

                      > .question {
                        > .yellow {
                        }
                      }

                      > .divider-and-answer {
                        height: $tipDividerAndAnswerExpandedHeightNotKO;
                      }
                    }

                    > .next-button {
                    }

                    > .close-button {
                    }
                  }
                }
              }
            }
          }
        }
      }

      h3 {
        background-color: $c38;
        padding: 12px 30px;
        border-radius: 0 100px 100px 100px;

        font-size: 28px;
        font-weight: bold;
        line-height: 1.6;
        letter-spacing: -0.84px;
        color: white;


        > .light {
          font-weight: normal;
        }
      }

      .unorderedList {
        font-size: 22px;
        font-weight: bold;
        line-height: 1.6;
        letter-spacing: -0.66px;
        color: $c6B;
        list-style-type: disc;
        margin-left: 1.2em;

        li {
          list-style-type: disc;

        }
      }

      .head-graph-wrapper {
        margin: 0 auto;
        width: 760px;
        height: 760px;
        position: relative;

        .head-bg {
          width: 100%;
          height: 100%;
        }

        .head-graph-line {
          position: absolute;
          left: 180px;
          top: 160px;
          width: 400px;
          height: 400px;
        }

        .label-wrapper {
          position: absolute;

          display: flex;
          align-items: center;
          gap: 10px;

          > .ball {
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: $kpBlue600;
            border-radius: 200px;
            width: 40px;
            height: 40px;

            font-size: 22px;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: -0.66px;
            color: white;
          }

          > .label {
            font-size: 18px;
            font-weight: normal;
            line-height: 1.4;
            letter-spacing: -0.54px;
            color: $kpNavy400;

            width: 110px;
            display: flex;
            flex-direction: column;

            > .strong {
              font-weight: bold;
              color: $kpNavy500Main;
            }
          }

          &.planning {
            flex-direction: column-reverse;

            left: 325px;
            bottom: 620px;

            > .label {
              align-items: center;
            }
          }

          &.attention {
            flex-direction: row-reverse;

            right: 600px;
            top: 335px;

            > .label {
              width: 110px;
              height: 50px;

              align-items: flex-end;
              justify-content: center;
            }
          }

          &.simultaneous {
            flex-direction: row;

            left: 600px;
            top: 322px;

            > .label {
              height: 75px;
              width: 120px;

              align-items: flex-start;
              justify-content: center;
            }
          }

          &.successive {
            flex-direction: column;

            left: 325px;
            top: 580px;

            > .label {
              align-items: center;
            }
          }

          &.notKO {
            > .label {
              > .light-en {
                display: none;
              }
            }
          }
        }

        .lines {
          position: absolute;
          top: 160px;
          left: 180px;
          width: 400px;
          height: 400px;

          > .canvas {
            position: absolute;
            top: -10px;
            left: -10px;
            width: 420px;
            height: 420px;
          }

          .brain {
            position: absolute;
            left: 165px;
            top: 165px;
            width: 70px;
            height: 70px;
            z-index: 3;
          }
        }
      }
    }

    .individualAnalysisCanvasWrapper {
      box-sizing: border-box;
      position: relative;
      width: 870px;
      height: 300px;
      margin: 0 auto;
      padding: 30px 0;

      .line {
        position: absolute;
        width: 870px;
        height: 45px;
        background-image: url('https://images.feel-good.io/report/individual_graph_line_bg_v_2.svg');
        top: 160px;
        left: 0;
        z-index: 2;
      }

      .individualAnalysisPoints {
        width: 770px;
        height: 70px;
        position: absolute;
        left: 15px;
        top: 51px;

        .point {
          position: absolute;
          bottom: 0;
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;
          text-align: center;

          > .label {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.4;
            letter-spacing: -0.54px;
            color: $c38;
            white-space: nowrap;
            padding: 0 5px;
          }

          > .value {
            padding: 0 10px;
            height: 40px;
            background-color: $c38;
            color: white;
            font-size: 22px;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: -0.66px;
            border-radius: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &.notKO {
          > .point {
            > .label {
              line-height: 1.2;
            }
          }
        }
      }

      .individualAnalysisCanvas {
        width: 1160px;
        height: 240px;
        position: absolute;
        left: -180px;
        top: 30px;
        z-index: 3;
      }

      .individualAnalysisAvg {
        position: absolute;
        //width: 400px;
        z-index: 1;
        background: linear-gradient(180deg, #ffdce4 0%, rgba(255, 243, 246, 0) 100%);
        top: 170px;
        left: 15px;

        display: flex;
        gap: 4px;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        padding: 50px 0 10px;

        > .label {
          font-size: 18px;
          font-weight: bold;
          line-height: 1.4;
          letter-spacing: -0.54px;
          color: $c38;
          flex-shrink: 0;
        }

        > .avgPoint {
          flex-shrink: 0;
          padding: 0 10px;
          border-radius: 200px;
          background-color: $cE6;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 22px;
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: -0.66px;
          height: 40px;
        }
      }
    }

    .table {
      width: 100%;
      border-collapse: collapse;

      &.fullScaleSummary {
        th {
          width: 216px;
        }
      }

      &.full-scale {
        th {
          height: 60px;
        }

        tbody {
          tr {
            height: 30px;
          }
        }

        td {
          width: 150px;

          &.twice {
            width: 250px;
          }

          &.validity {
            width: 205px;
          }
        }
      }

      &.subscale {
        thead {
          th {
            width: 192px;
            height: 50px;
          }
        }

        tbody {
          td,
          th {
            height: 30px;
          }
        }
      }

      &.individualAnalysis {
        thead {
          th {
            width: 240px;
            height: 100px;
          }
        }

        tbody {
          height: 30px;

          tr {
            height: 30px;

            th {
              height: 30px;
              width: 120px;
            }

            td {
              &:first-child {
                width: 120px;
              }

              &.scoreDiff {
                width: 150px;
              }

              &.learningStyle {
                width: 330px;
              }
            }
          }
        }
      }

      th,
      td {
        border: 1px solid $cA3;
        border-collapse: collapse;
      }

      th {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: -0.54px;
        color: $c38;

        vertical-align: middle;

        &:first-child {
          border-left: none;
        }

        //&:last-child {
        //  height: 60px;
        //  border-right: none;
        //}

        .light {
          font-weight: normal;
        }
      }

      td {
        text-align: center;
        vertical-align: middle;
        font-size: 18px;
        color: $c38;
        line-height: 1.4;
        letter-spacing: -0.54px;

        &:first-child {
          border-left: none;
        }

        //&:last-child {
        //  border-right: none;
        //}

        &.head {
        }

        .red {
          font-weight: bold;
          color: $cE6;
        }

        .strong {
          font-weight: bold;
        }
      }

      th {
        background-color: $cF1;
      }
    }

    .paragraph {
      font-size: 22px;
      font-weight: normal;
      line-height: 1.6;
      letter-spacing: -0.66px;
      color: $c6B;

      .strong {
        font-weight: bold;
        color: $c38;
      }

      .blue {
        font-weight: bold;
        color: $c77;
      }

      .deepblue {
        font-weight: bold;
        color: $c58;
      }

      &.special {
        padding: 40px;
        background-color: $cF6F3;
        border-radius: 20px;

        .underLine {
          text-decoration-line: underline;
        }
      }
    }

    .divider {
      width: 100%;
      border-top: 1px dashed $c76;
    }

    .result {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      > .title {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h4 {
          display: flex;
          gap: 12px;
          align-items: center;

          font-size: 22px;
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: -0.66px;
          color: $c38;

          .numbering {
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;

            background-color: $c38;
            border-radius: 6px;

            color: white;
          }

          .blue {
            font-weight: bold;
            color: $c77;
          }
        }
      }

      > .above12-ch04-content {
        margin-top: -34px;
        list-style: disc;
        margin-left: 1.5em;

        > .sentence {
          font-size: 22px;
          font-weight: normal;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $g500Subtext;

          &.bottom-space {
            margin-bottom: 2em;
          }
        }

        > .title {
          margin-left: -1em;
          font-size: 22px;
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: #7762df;
        }

        > .heading5 {
        }
      }

      > .content {
        display: flex;
        justify-content: center;

        &.judgementResult {
          position: relative;

          > .point {
            position: absolute;
            left: 0;
            bottom: 68px;
            display: flex;
            flex-direction: column;
            align-items: center;

            > .score {
              box-shadow: 0 2px 4px 0 #00000040;
              padding: 2.5px 10px;
              background-color: $cE6;
              border-radius: 200px;
              font-size: 22px;
              font-weight: bold;
              line-height: 1.5;
              letter-spacing: -0.66px;
              color: white;
            }

            > .tail {
              width: 18px;
              margin-top: -2px;
            }

            > .circle {
              box-shadow: 0 2px 4px 0 #00000040;
              margin-top: -8px;
              box-sizing: border-box;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              border: 4px solid $cE6;
              background-color: white;
            }
          }
        }
      }
    }
  }

  .summaryAndExplanation {
    background-color: $cF6;
    border-radius: 10px;
    padding: 0;
    transition: all 0.5s ease-in-out;

    display: flex;
    flex-direction: column;

    &.notKO {
      display: none;
    }

    > .summaryAndExplanationHeader {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;

      > .title {
        font-size: 22px;
        font-weight: bold;
        line-height: 1.6;
        letter-spacing: -0.66px;
        color: $c38;
      }

      > .spreadButton {
        display: flex;
        gap: 6px;
        align-items: flex-start;

        color: $c38;
        font-size: 18px;
        font-weight: normal;
        line-height: 1.4;
        letter-spacing: -0.54px;
        cursor: pointer;

        &.hide {
          transition: all 0.5s ease-in-out;
          display: none;
        }

        > img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .contentParagraphWrapper {
      box-sizing: border-box;
      padding: 0 30px 20px 30px;
      transition: all 0.5s ease-in-out;
      overflow: hidden;

      &.hide {
        height: 0 !important;
        padding: 0 30px;
      }

      .contentParagraph {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        font-size: 22px;
        font-weight: normal;
        line-height: 1.6;
        letter-spacing: -0.66px;
        color: #6b6b6b;

        > .strong {
          font-weight: bold;
          color: $c38;
        }

        .blue {
          font-weight: bold;
          color: $c77;
        }

        > .orderingList {
          margin-left: 1.2em;
          list-style: decimal;

          > li {
            font-weight: bold;

            > .itemContent {
              font-weight: normal;
            }

            > .secondOrderingList {
              margin-left: 1.2em;
              list-style: lower-alpha;
              font-weight: normal;

            }

            li {
              list-style: lower-alpha;
            }
          }

          li {
            list-style: decimal;
          }
        }
      }
    }
  }
}

.contentFooter {
  width: 100%;
  border: none;
  border-top: 4px solid $c76;
}
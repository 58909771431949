$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@import "print";

.guideBannerWrapper {
  margin-top: 90px;

  &.learningCard{
    margin-left: -40px;
    margin-top: 0;
    margin-bottom: 50px;
  }

  .guideBanner {
    box-sizing: border-box;
    background-color: #383649;
    width: 1100px;
    padding: 30px 60px 40px 60px;
    border-radius: 6px 80px 80px 60px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.25);
    position: relative;

    display: flex;
    justify-content: space-between;

    > .bannerTexts {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 695px;

      > .bannerTitle {
        display: flex;
        gap: 6px;
        row-gap: 0;
        align-items: flex-end;
        flex-wrap: wrap;

        font-size: 28px;
        font-weight: normal;
        line-height: 1.6;
        letter-spacing: -0.84px;
        color: white;

        > .pointWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          > .newBadge {
            margin-bottom: -3px;
            position: relative;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.24px;
            color: white;
            padding: 2px 12px;
            background-color: #E60063;
            border-radius: 100px;

            > .tail {
              width: 14px;
              height: 7.5px;
              position: absolute;
              right: 12px;
              bottom: -6px;
            }
          }

          > .point {
            padding: 0 20px;
            background-color: #583BE2;
            border-radius: 100px;
            font-weight: bold;
          }
        }
      }

      .guideChecker {
        > .bannerDescription {
          font-size: 22px;
          font-weight: normal;
          line-height: 1.6;
          letter-spacing: -0.66px;
          color: #A3A0BA;
        }
      }

      > .bannerDescription {
        font-size: 22px;
        font-weight: normal;
        line-height: 1.6;
        letter-spacing: -0.66px;
        color: #A3A0BA;
      }
    }

    .strong {
      font-weight: bold;
    }

    > .guideLinkButtonWrapper {
      width: 295px;
      height: 240px;
      position: absolute;
      bottom: 30px;
      right: 30px;

      display: flex;
      flex-direction: column;
      align-items: center;

      &.learningCardWrapper{
        width: 295px;
        height: 109px;
        position: absolute;
        right: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      > .linkey {
        width: 240px;
        height: 240px;
      }

      > .linkButton {
        margin-top: -111px;
        padding: 24px 40px 24px 45px;
        background: linear-gradient(170deg, #5438d8 14.05%, #37248d 87.35%);
        border-radius: 200px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
        cursor: pointer;
        position: relative;
        box-sizing: border-box;

        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;

        &.learningCardButton{
          margin-top: 0;
        }

        &.hover {
          &:hover {
            > .hoverBg {
              opacity: 1;
            }
          }
        }

        > .hoverBg {
          background: linear-gradient(170deg, #e60063 14.05%, #a22446 87.35%);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 200px;
          opacity: 0;
          transition: all 0.5s ease-in-out;
        }

        > .buttonLabel {
          position: relative;
          font-size: 22px;
          font-weight: normal;
          line-height: 1.3;
          letter-spacing: -0.66px;
          color: white;
          flex-shrink: 0;
          text-align: right;

          > .strong {
            font-weight: bold;
            line-height: 1.6;

            &.en {
              line-height: 1.3;
            }
          }
        }

        > .icon {
          position: relative;
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@import "../print";

@font-face {
  font-family: 'NanumSquareRound';
  font-weight: 300;
  font-style: normal;
  src: url(https://cdn.jsdelivr.net/gh/webfontworld/naver/NanumSquareRoundL.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'NanumSquareRound';
  font-weight: 400;
  font-style: normal;
  src: url(https://cdn.jsdelivr.net/gh/webfontworld/naver/NanumSquareRoundR.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'NanumSquareRound';
  font-weight: 500;
  font-style: normal;
  src: url(https://cdn.jsdelivr.net/gh/webfontworld/naver/NanumSquareRoundB.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'NanumSquareRound';
  font-weight: 700;
  font-style: normal;
  src: url(https://cdn.jsdelivr.net/gh/webfontworld/naver/NanumSquareRoundEB.woff2) format('woff2');
  font-display: swap;
}

.pageWrapper{
  page-break-before: always;
  box-sizing: border-box;
  width: 1160px;
  margin: 50px auto 0;
  border: 2px solid #E5E5EF;
  border-radius: 20px;
  padding: 50px 70px;
  background-color: white;
  position: relative;
  overflow: hidden;

  > .background3 {
    position: absolute;
    width: 508px;
    top: 0;
    left: 0;
  }

  > .background4 {
    position: absolute;
    width: 244px;
    top: 757px;
    right: 0;
  }
}
.content {
  display: flex;
  justify-content: center;
  width: 1030px;

  > .section {
    display: flex;
    z-index: 1;

    > .imageWrapper {
      position: relative;
      display: inline-block;
      height: 900px;

      > .cards {
        width: 500px;
        height: 900px;
      }

      > .cardDesc{
        position: absolute;
        width: 460px;
        height: 35px;
        color: var(--GrayScale-white, #fff);
        text-align: center;

        &.desc1{
          top: 166px;
          left: 20px;

          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 35.2px */
          letter-spacing: -0.66px;
        }

        &.desc2{
          position: absolute;
          width: 460px;
          height: 84px;
          top: 205px;
          left: 20px;
          text-align: center;
          text-shadow: 1px 2px 5px rgba(209, 110, 30, 0.2);
          -webkit-text-stroke-width: 6px;
          -webkit-text-stroke-color: #fff;
          font-size: 60px;
          font-style: normal;
          font-weight: 800;
          line-height: 140%;
          letter-spacing: -6.3px;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          z-index: 2;
          font-family: NanumSquareRound, sans-serif;
        }
      }

      //> .cardDesc1 {
      //  position: absolute;
      //  width: 460px;
      //  height: 35px;
      //  top: 166px;
      //  left: 20px;
      //  color: var(--GrayScale-white, #fff);
      //  text-align: center;
      //
      //  font-size: 22px;
      //  font-style: normal;
      //  font-weight: 400;
      //  line-height: 160%; /* 35.2px */
      //  letter-spacing: -0.66px;
      //}
      //
      //> .cardDesc2 {
      //  position: absolute;
      //  width: 460px;
      //  height: 84px;
      //  top: 205px;
      //  left: 20px;
      //  text-align: center;
      //  text-shadow: 1px 2px 5px rgba(209, 110, 30, 0.2);
      //  -webkit-text-stroke-width: 6px;
      //  -webkit-text-stroke-color: #fff;
      //  font-family: NanumSquareRound, sans-serif;
      //  font-size: 60px;
      //  font-style: normal;
      //  font-weight: 800;
      //  line-height: 140%; /* 84px */
      //  letter-spacing: -6.3px;
      //  // background: linear-gradient(266deg, #f4964b 13.29%, #cd6919 99.33%);
      //  background-clip: text;
      //  -webkit-background-clip: text;
      //  -webkit-text-fill-color: transparent;
      //  z-index: 2;
      //}

      > .card-desc-wrapper2 {
        width: 460px;
        position: absolute;
        bottom: 600px;
        left: 20px;
        text-align: center;
      }

      > .card-desc-wrapper2 > .card-desc2 {
        width: 460px;
        height: 84px;
        text-align: center;
        text-shadow: 1px 2px 5px rgba(209, 110, 30, 0.2);
        -webkit-text-stroke-width: 6px;
        -webkit-text-stroke-color: #fff;
        font-family: NanumSquareRound, sans-serif;
        font-size: 60px;
        font-style: normal;
        font-weight: 800;
        line-height: 140%;
        letter-spacing: -6.3px;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 2;
      }

      > .cardDesc3 {
        position: absolute;
        width: 460px;
        height: 84px;
        top: 205px;
        left: 20px;
        text-align: center;
        text-shadow: 1px 2px 5px rgba(209, 110, 30, 0.2);
        font-family: NanumSquareRound, sans-serif;
        font-size: 60px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 84px */
        letter-spacing: -6.3px;
        color: #f4a418;
        background-clip: text;
        z-index: 3;
      }

      > .cardDesc3Blue {
        position: absolute;
        width: 460px;
        height: 84px;
        top: 205px;
        left: 20px;
        text-align: center;
        text-shadow: 1px 2px 5px rgba(209, 110, 30, 0.2);
        font-family: NanumSquareRound, sans-serif;
        font-size: 60px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -6.3px;
        color: #43a5ff;
        background-clip: text;
        z-index: 3;
      }

      > .card-desc-wrapper2 > .card-desc3 {
        width: 460px;
        height: 84px;
        text-align: center;
        text-shadow: 1px 2px 5px rgba(209, 110, 30, 0.2);
        font-family: NanumSquareRound, sans-serif;
        font-size: 60px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 84px */
        letter-spacing: -6.3px;
        color: #f4a418;
        background-clip: text;
        z-index: 3;
      }

      > .card-desc-wrapper2 > .card-desc3-blue {
         width: 460px;
         height: 84px;
         text-align: center;
         text-shadow: 1px 2px 5px rgba(209, 110, 30, 0.2);
         font-family: NanumSquareRound, sans-serif;
         font-size: 60px;
         font-style: normal;
         font-weight: 500;
         line-height: 140%; /* 84px */
         letter-spacing: -6.3px;
         color: #43a5ff;
         background-clip: text;
         z-index: 3;
       }

      > .cardHashtag {
        position: absolute;
        bottom: 160px;
        display: flex;
        width: 500px;
        justify-content: center;
        align-items: flex-start;
        gap: 30px;

        > .hashTagDesc {
          height: 35px;
          color: var(--K-PASS-KPblue-100, #d6ceff);
          text-align: center;

          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 35.2px */
          letter-spacing: -0.66px;
        }
      }

      > .card2Title {
        position: absolute;
        top: 43px;
        left: 50%;
        transform: translateX(-50%);

        width: 290px;
        color: #fff;
        text-align: center;

        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 35.2px */
        letter-spacing: -0.66px;
      }

      > .card2NameWrapper {
        position: absolute;
        width: 350px;
        height: 50px;
        top: 90px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        > .name {
          color: #000;
          text-align: center;

          font-size: 35px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 56px */
          letter-spacing: -1.05px;
        }

        > .subName {
          margin-left: 3px;
          color: var(--K-PASS-KPblue-500_main, #583be2);
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%;
          letter-spacing: -0.66px;
        }
      }

      > .card2BrainDesc {
        position: absolute;
        top: 508px;
        display: flex;
        width: 500px;

        .brain{
          color: var(--K-PASS-KPblue-500_main, #583be2);
          text-align: center;
          text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

          //-webkit-text-stroke-width: 1;
          -webkit-text-stroke-color: var(--GrayScale-white, #fff);
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 25.2px */
          letter-spacing: -0.54px;

          &.left{
            margin-left: 115px;
          }

          &.right{
            margin-left: 91px;
          }

          &.plan{
            margin-left: 80px;
          }
        }
      }

      > .card2TextWrapper {
        position: absolute;
        top: 570px;
        left: 20px;

        > .card2LearningWrapper {
          display: flex;
          left: 20px;

          &.secondContent{
            margin-top: 30px;
          }

          .card2Image {
            float: left;
            width: 50px;
            height: 50px;
            margin-left: 25px;
            margin-right: 15px;
            margin-top: 5px;
          }

          .card2DescWrapper{
            float: left;

            .title{
              width: 320px;
              color: var(--K-PASS-KPblue-800, #23185a);
              font-size: 28px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
              letter-spacing: -0.84px;
            }

            .desc{
              width: 320px;
              color: var(--K-PASS-KPnavy-500_main, #383649);
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.54px;
            }
          }
        }
      }
    }
  }
}
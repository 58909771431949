$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@import "../../variables";
@import "../print";

// ~~Strategy

.pageBody {
  display: flex;
  flex-direction: column;
  gap: 120px;
  padding: 40px 30px;
}

.pageBody article.article {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.pageBody article.article h2.title {
  padding-left: 30px;
  border-radius: 0 100px 100px 100px;
  background-color: #383649;
  height: 69px;
  font-size: 28px;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 0.84px;
  line-height: 69px;
}
.pageBody article.article article.subArticle {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 20px;
}
.pageBody article.article article.subArticle section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.pageBody article.article article.subArticle section h3.title {
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-size: 22px;
  font-weight: bold;
  line-height: 160%;
  letter-spacing: -0.66px;
  color: #383649;

  list-style: none;
}
.pageBody article.article article.subArticle section h3.title .number {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 6px;
  background-color: #383649;
  color: #FFFFFF;
  text-align: center;
}

.pageBody article.article article.subArticle section ul {
  list-style: none;
}
.pageBody article .description {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 35.2px */
  letter-spacing: -0.66px;
  color: #6B6B6B;
}

.pageBody article .description .bold{
  font-weight: bold !important;
  color: #383649 !important;
}
.pageBody article .description dl,
.pageBody article .description h4.title,
.pageBody article .description .subTitle {
  color: #462FB5;
  font-size: 22px;
  font-weight: bold;
}
.pageBody article .description dt {
  font-weight: bold;
  display: list-item;
  margin-left: 1.3em;
  position: relative;
}
.pageBody article .description dt.floatLeft {
  float: left;
}
.pageBody article .description dt.floatLeft:after {
  content: "  |  ";
}
.pageBody article .description dd {
  margin-left: 35px;
}
.pageBody article .description ol {
  list-style: decimal;
}
.pageBody article .description ol li {
  margin-left: 1.5em;
}
.pageBody article .description ol li h4 {
  font-weight: bold;
  font-size: 22px;
  line-height: 1.6;
}
.pageBody article ul.description {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.pageBody article.summary {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-top: 40px;
}
.pageBody article.summary .titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
}
.pageBody article.summary .titleWrapper h2.title {
  font-size: 85px;
  line-height: 130%;
  letter-spacing: -2.55px;
  color: #383649;
  word-break: break-word;
}

.pageBody article.summary .titleWrapper .title .normalText {
  font-size: 85px;
  font-weight: normal;
  line-height: 130%;
  letter-spacing: -2.55px;
  color: #383649;
  word-break: break-word;
}
.pageBody article.summary .titleWrapper h2.title span {
  display: block;
}
.pageBody article.summary .titleWrapper h2.title .light {
  font-size: 65px;
  font-weight: normal;
  letter-spacing: -1.95px;
  line-height: 1.3;
}
.pageBody article.summary .titleWrapper h2.title .small {
  font-size: 55px;
  letter-spacing: -1.65px;
}
.pageBody article.summary .titleWrapper h2.title.notKO {
  letter-spacing: -0.03em;
}
.pageBody article.summary .titleWrapper h2.title.notKO > .light {
  font-size: 50px;
  line-height: 1.3;
}
.pageBody article.summary .titleWrapper h2.title.notKO > .normalText {
  font-size: 70px;
  line-height: 1.3;
}
.pageBody article.summary .titleWrapper h2.title.notKO > .bold {
  font-size: 70px;
  line-height: 1.3;
}
.pageBody article.summary .titleWrapper .descriptionWrapper {
  padding: 40px;
  width: 460px;
  box-sizing: border-box;
  background-color: #F6F3FF;
  flex-shrink: 0;
  border-radius: 20px;
}
.pageBody article.summary .titleWrapper .descriptionWrapper .bold {
  font-weight: bold !important;
  color: #383649 !important;
}
.pageBody .underline {
  display: inline-block;
  width: 100%;
  height: 11px;
  border: none;
  background-image: url("https://images.feel-good.io/guide/underline.svg");
}

body .underlineText {
  text-decoration: underline;
}

// learningStrategyGuide
.tagWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
  list-style: none;
}
.tagWrap .tag {
  padding: 10px 16px;
  color: #383649;
  background-color: #F6F3FF;
  border-radius: 6px;
}
.tagWrap .tag .eng {
  font-size: 18px;
  color: #462FB5;
}
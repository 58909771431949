$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@import '../variables';
@import "print";
@keyframes shiningButtonBefore {
  from {
    opacity: 0.5;
    transform: translateX(-100px) skewX(-15deg);
  }

  to {
    opacity: 0.6;
    transform: translateX(var(--translateX-value)) skewX(-15deg);
  }
}

@keyframes shiningButtonAfter {
  from {
    opacity: 0;
    transform: translateX(-100px) skewX(-15deg);
  }

  to {
    opacity: 1;
    transform: translateX(var(--translateX-value)) skewX(-15deg);
  }
}

$tipWrapperDefaultHeight: 160px;
$tipTextBoxDefaultHeight: 86px;
$tipTextBoxDefaultWidth: 257px;
$tipTextBoxTopSpaceDefaultHeight: calc((#{$tipWrapperDefaultHeight} - #{$tipTextBoxDefaultHeight}) / 2);
$tipTextDefaultHeight: 35px;
$tipTextDefaultWidth: 149px;
$tipDividerAndAnswerDefaultWidth: 0;
$tipDividerAndAnswerDefaultHeight: 0;

$tipTextBoxDefaultHeightNotKO: 86px;
$tipTextBoxDefaultWidthNotKO: 335px;
$tipTextBoxTopSpaceDefaultHeightNotKO: calc((#{$tipWrapperDefaultHeight} - #{$tipTextBoxDefaultHeightNotKO}) / 2);
$tipTextDefaultHeightNotKO: 35px;
$tipTextDefaultWidthNotKO: 227px;

$tipWrapperExpandedHeight: 260px;

$tipTextBoxExpandedHeight: 208px;
$tipTextBoxExpandedWidth: 100%;
$tipTextBoxTopSpaceExpandedHeight: calc((#{$tipWrapperExpandedHeight} - #{$tipTextBoxExpandedHeight}) / 2);
$tipTextExpandedHeight: 160px;
$tipTextExpandedWidth: 632px;
$tipDividerAndAnswerExpandedMarginTop: 10px;
$tipDividerAndAnswerExpandedHeight: calc(#{$tipTextExpandedHeight} - #{$tipTextDefaultHeight} - #{$tipDividerAndAnswerExpandedMarginTop} + 9px);

$tipTextBoxExpandedMarginBottomNotKO: 20px;
$tipTextBoxExpandedHeightNotKO: 243px;
$tipTextBoxExpandedTotalHeightNotKO: calc(#{$tipTextBoxExpandedHeightNotKO} + #{$tipTextBoxExpandedMarginBottomNotKO});
$tipTextBoxExpandedWidthNotKO: 100%;
$tipTextBoxTopSpaceExpandedHeightNotKO: 0;
$tipTextExpandedHeightNotKO: 195px;
$tipTextExpandedWidthNotKO: 632px;
$tipDividerAndAnswerExpandedHeightNotKO: calc(#{$tipTextExpandedHeightNotKO} - #{$tipTextDefaultHeight} - #{$tipDividerAndAnswerExpandedMarginTop});


.darkmongTip {
  .darkmongSpeechWrapper {
    margin-bottom: -30px;
    width: 100%;
    height: $tipWrapperDefaultHeight;

    overflow: hidden;

    flex-direction: column;
    display: flex;

    transition: $darkmongBoxTransitionValue;

    > .darkmongImageAndTextWrapper {
      display: flex;
      gap: 20px;
      align-items: flex-start;
      height: $tipWrapperDefaultHeight;

      > .darkmongImgBox {
        flex-shrink: 0;

        width: 180px;
        height: 100%;

        > img {
          width: 100%;
        }
      }

      > .textBoxWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        > .topSpace {
          width: 100%;
          height: $tipTextBoxTopSpaceDefaultHeight;
          transition: $darkmongBoxTransitionValue;
        }

        > .textBox {
          box-sizing: border-box;
          position: relative;
          transition: $darkmongBoxTransitionValue;

          width: $tipTextBoxDefaultWidth;
          height: $tipTextBoxDefaultHeight;

          cursor: pointer;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
          border-radius: 20px;
          background: linear-gradient(180deg, #583be2 0%, #25179f 100%);
          padding: 24px 30px;
          overflow: hidden;

          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          $animationDuration: 1.5s;
          --translateX-value: 400px;

          &:before {
            content: '';
            display: block;
            position: absolute;
            background: rgba(255, 255, 255, 0.7);
            width: 20px;
            height: 120%;
            left: 0;
            top: -10%;
            filter: blur(20px);

            animation-name: shiningButtonBefore;
            animation-duration: $animationDuration;
            animation-iteration-count: infinite;
          }

          &:after {
            content: '';
            display: block;
            position: absolute;
            background: rgba(255, 255, 255, 0.5);
            width: 10px;
            height: 120%;
            left: 30px;
            top: -10%;
            filter: blur(10px);

            animation-name: shiningButtonAfter;
            animation-duration: $animationDuration;
            animation-iteration-count: infinite;
          }

          > .text {
            font-size: 22px;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: -0.03em;
            color: white;

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            overflow: hidden;
            width: $tipTextDefaultWidth;
            height: $tipTextDefaultHeight;

            > .question {
              > .yellow {
                color: #ffe606;
              }
            }

            > .dividerAndAnswer {
              transition: $darkmongBoxTransitionValue;
              opacity: 0;
              display: flex;
              flex-direction: column;
              //gap: 10px;

              width: $tipDividerAndAnswerDefaultWidth;
              height: $tipDividerAndAnswerDefaultHeight;

              overflow: hidden;
            }
          }

          > .nextButton {
            align-self: center;
            display: flex;
            justify-content: center;
            align-items: center;

            width: 38px;
            height: 40px;
            padding: 0;
            cursor: pointer;
          }

          > .closeButton {
            display: none;
            cursor: pointer;
          }
        }
      }
    }

    &.notKO {
      > .darkmongImageAndTextWrapper {
        > .darkmongImgBox {
        }

        > .textBoxWrapper {
          > .topSpace {
            height: $tipTextBoxTopSpaceDefaultHeightNotKO;
          }

          > .textBox {
            width: $tipTextBoxDefaultWidthNotKO;

            > .text {
              width: $tipTextDefaultWidthNotKO;
              height: $tipTextDefaultHeightNotKO;

              > .question {
                > .yellow {
                }
              }

              > .dividerAndAnswer {
              }
            }

            > .nextButton {
            }

            > .closeButton {
            }
          }
        }
      }
    }

    &.expanded {
      width: 100%;
      height: $tipWrapperExpandedHeight;

      > .darkmongImageAndTextWrapper {
        height: fit-content;

        > .darkmongImgBox {
          height: $tipWrapperExpandedHeight;
        }

        > .textBoxWrapper {
          > .topSpace {
            height: $tipTextBoxTopSpaceExpandedHeight;
          }

          > .textBox {
            cursor: default;
            box-sizing: border-box;
            width: $tipTextBoxExpandedWidth;
            height: $tipTextBoxExpandedHeight;
            justify-content: space-between;

            &:before {
              display: none;
            }

            &:after {
              display: none;
            }

            > .text {
              width: $tipTextExpandedWidth;
              height: $tipTextExpandedHeight;

              > .dividerAndAnswer {
                //margin-top: $tipDividerAndAnswerExpandedMarginTop;
                height: $tipDividerAndAnswerExpandedHeight;
                width: 100%;
                display: flex;
                transition: $darkmongBoxTransitionValue;
                opacity: 1;

                > .divider {
                  width: 100%;
                  border-top: 1px dashed #d6ceff;
                }

                > .answer {
                  font-weight: normal;
                  color: #d6ceff;

                  > .strong {
                    font-weight: bold;
                    color: white;
                  }
                }
              }
            }

            > .nextButton {
              display: none;
            }

            > .closeButton {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;

              padding: 20px 0;

              > img {
                width: 38px;
                height: 40px;
              }
            }
          }
        }
      }

      &.notKO {
        height: $tipTextBoxExpandedTotalHeightNotKO;

        > .darkmongImageAndTextWrapper {
          > .darkmongImgBox {
          }

          > .textBoxWrapper {
            > .topSpace {
              height: $tipTextBoxTopSpaceExpandedHeightNotKO;
            }

            > .textBox {
              margin-bottom: $tipTextBoxExpandedMarginBottomNotKO;
              height: $tipTextBoxExpandedHeightNotKO;

              > .text {
                height: $tipTextExpandedHeightNotKO;

                > .question {
                  > .yellow {
                  }
                }

                > .dividerAndAnswer {
                  height: $tipDividerAndAnswerExpandedHeightNotKO;
                }
              }

              > .nextButton {
              }

              > .closeButton {
              }
            }
          }
        }
      }
    }
  }
}

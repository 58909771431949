$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

textarea::placeholder, input::placeholder {
  color: var(--GrayScale-G4-C6C6C6, #C6C6C6);
}

@mixin textStyle {
  align-self: center;
  color: var(--FeelGood-Color-FeelGood-Color-FG-Dark-blue-344151, #757575);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;

}

.text{
  display: flex;
  align-items: center;
  gap: 20px;

  color: var(--GrayScale-G2-757575, #757575);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
}

.postWrapper {
  display: flex;
  width: 958px;
  //padding: 30px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);
  background: var(--GrayScale-W-FFFFFF, #FFF);

  .mainTitle {
    color: var(--FeelGood-Color-FeelGood-Color-FG-Dark-blue-344151, #344151);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 32px */
    letter-spacing: -0.6px;
  }

  // 중복확인을 사용하는 컴포넌트 래퍼
  .mainInputWrapper {
    width: 918px;
    min-height: 46px;
    display: flex;

    .inputWrapper {
      width: 804px;
      min-height: 46px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .inputArea {
        width: 700px;
        height: 46px;
        flex-shrink: 0;
        background: var(--GrayScale-W-FFFFFF, #FFF);

        .input {
          align-items: center;
          padding-left: 12px;
          width: 700px;
          height: 46px;

          border-top: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);
          border-bottom: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);
          border-left: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);

        }
      }

    }


    // 상담 소속주소
    .addressInputWrapper {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      width: 918px;
      max-height: 68px;

      .addressWrapper{
        display: flex;
        width: 804px;
        height: 46px;

        .addressArea {
          width: 350px;
          height: 46px;
          display: flex;
          background: var(--GrayScale-W-FFFFFF, #FFF);
          border: 1px solid var(--GrayScale-G5-dedede, #DEDEDE); // 상단 테두리만 부모에 적용

          padding: 5px;

          .address {
            padding-left: 14px;
            width: 100%;
            height: 100%;
            border: none;

            &.text {
              color: var(--GrayScale-G2-757575, #757575);
              @include textStyle
            }

          }

          .addressSearchArea {
            align-self: center;
            min-width: 65px;
            display: inline-flex;
            padding: 1px 6px;
            margin-right: 14px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 2px;
            border: 1px solid var(--FeelGood-Color-FeelGood-Color-FG-Dark-blue-344151, #344151);
            background: var(--GrayScale-W-FFFFFF, #FFF);

            > .text {
              cursor: pointer;
              color: var(--FeelGood-Color-FeelGood-Color-FG-Dark-blue-344151, #344151);
              @include textStyle;
            }
          }

          &.close {
            width: 454px;
            border: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);
            background: var(--GrayScale-W-FFFFFF, #FFF);
          }
        }
      }
    }

    // 상담 계좌정보
    .accountWrapper {
      display: flex;
      width: 300px;
      flex-direction: column;
      align-items: flex-start;

    }
    .shortInputWrapper{
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    .shortInput {
      padding-left: 16px;
      width: 804px;
      height: 46px;
      flex-shrink: 0;
      border: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);
      background: var(--GrayScale-W-FFFFFF, #FFF);

    }


    // 중복확인버튼
    .duplicateCheckButton {
      cursor: pointer;
      background-color: #646f96;
      border: 1px solid #dedede;
      border-left-width: 0;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      height: 46px;
      letter-spacing: -.03em;
      line-height: 1.6;
      min-width: 104px;
      padding: 12px 19px;
      text-align: center;
    }
  }

  .inValidateText{
    color: #E60063;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.42px;
  }

  .validateText{
    color: #583BE2;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.42px;
  }

  .longInputWrapper {
    display: flex;
    width: 918px;
    justify-content: space-between;
    align-items: flex-start;

    .longInput {
      display: flex;
      width: 804px;
      height: 96px;
      padding: 12px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);
      background: var(--GrayScale-W-FFFFFF, #FFF);
    }
  }

  // 금액 입력하는 컴포넌트 래퍼
  .priceMainWrapper{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 60px;
  }

  // 분배금 관련 래퍼
  .priceInputWrapper{
    display: flex;
    width: 232px;
    gap: 10px;

    .priceArea {
      @include textStyle;

      display: flex;
      align-items: center;
      gap: 22px;
      width: 100px;
      height: 46px;
      border: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);
      background: var(--GrayScale-W-FFFFFF, #FFF);

      .input {
        margin-left: 34px;
        width: 53px;
        height: 22px;
        border: none;
      }
    }
  }



  // 세금 관련 래퍼
  .taxInputWrapper{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 60px;

    .taxArea{
      display: flex;
      width: 232px;
      align-items: center;
      gap: 10px;

      .selectRadioWrapper{
        display: flex;
        align-items: flex-start;
        @include textStyle
      }
    }
  }


  .inputText {
    @include textStyle;
  }

  .childrenWrapper{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
  }

  .invisibleBox{
    min-width: 104px;
    height: 46px;
  }

  .descriptionText {
    @include textStyle;

    align-self: stretch;
    color: #757575;
  }

  .buttonsWrapper{
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    .registerButton{
      width: 300px;
      height: 60px;
      background: var(--FeelGood-Color-FeelGood-Corporate-Color-FG-blue-2E75CC, #2E75CC);

      > .text{
        width: 130px;
        height: 32px;
        flex-shrink: 0;
        color: var(--GrayScale-W-FFFFFF, #FFF);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 32px */
        letter-spacing: -0.6px;
      }
    }
  }
}

.postForm {
  > div:last-child {
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    > button:first-child {
      width: 300px;
      height: 60px;
      flex-shrink: 0;
      padding: 0;
    }
  }
}
$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@import '../variables';
@import "print";
.headGraphWrapper {
  margin: 0 auto;
  width: 760px;
  height: 760px;
  position: relative;

  .headBg {
    width: 100%;
    height: 100%;
  }

  .headGraphLine {
    position: absolute;
    left: 180px;
    top: 160px;
    width: 400px;
    height: 400px;
  }

  .labelWrapper {
    position: absolute;

    display: flex;
    align-items: center;
    gap: 10px;

    > .ball {
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: $kpBlue600;
      border-radius: 200px;
      width: 40px;
      height: 40px;

      font-size: 22px;
      font-weight: bold;
      line-height: 1.6;
      letter-spacing: -0.66px;
      color: white;
    }

    > .label {
      font-size: 18px;
      font-weight: normal;
      line-height: 1.4;
      letter-spacing: -0.54px;
      color: $kpNavy400;

      width: 110px;
      display: flex;
      flex-direction: column;

      > .strong {
        font-weight: bold;
        color: $kpNavy500Main;
      }
    }

    &.planning {
      flex-direction: column-reverse;

      left: 325px;
      bottom: 620px;

      > .label {
        align-items: center;
      }
    }

    &.attention {
      flex-direction: row-reverse;

      right: 600px;
      top: 335px;

      > .label {
        width: 110px;
        height: 50px;

        align-items: flex-end;
        justify-content: center;
      }
    }

    &.simultaneous {
      flex-direction: row;

      left: 600px;
      top: 322px;

      > .label {
        height: 75px;
        width: 120px;

        align-items: flex-start;
        justify-content: center;
      }
    }

    &.successive {
      flex-direction: column;

      left: 325px;
      top: 580px;

      > .label {
        align-items: center;
      }
    }

    &.notKO {
      > .label {
        > .light-en {
          display: none;
        }
      }
    }
  }

  .lines {
    position: absolute;
    top: 160px;
    left: 180px;
    width: 400px;
    height: 400px;

    > .canvas {
      position: absolute;
      top: -10px;
      left: -10px;
      width: 420px;
      height: 420px;
    }

    .brain {
      position: absolute;
      left: 165px;
      top: 165px;
      width: 70px;
      height: 70px;
      z-index: 3;
    }
  }
}
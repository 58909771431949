@page {
  size: 1160px 1580px !important;
  margin: 0 0 0 0;
  //size: A4 !important;
}

@media print {

  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;

    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  body {
    background-color: white;
    margin: 0;

    break-inside: avoid!important;

  }

  .page {
    page-break-before: auto!important;
    break-inside: avoid!important;
    width: 1160px !important;
    height: 100% !important;
    //padding: 20px 70px !important;
    margin: 0 !important;
    //margin: 20px 0 0 0!important;
    border: none !important;
    box-sizing: border-box !important;

    > .content-header {
      display: none;
    }

    > .content-footer {
      display: none;
    }

    > .content {
      padding-top: 0;
      padding-bottom: 0;
    }

    &:last-child {
      page-break-after: always!important;
      margin-bottom: 100px !important;
    }

    .table {
      page-break-inside: avoid;
      break-inside: avoid;
    }

    vertical-align: baseline !important;
  }
  .first{
    margin: 0 auto!important;
    border: none!important;
    position: relative!important;
    z-index: 8!important;
    height: 1580px!important;
  }

  // 최하단 여백 추가
  .emptySpace{
    display: block!important;
  }

  .summaryWrapper .summaryWrapper{
    width: 100% !important;
    height: 100% !important;
  }

  // 선택적 미노출 스타일
  .noPrint {
    width: 0!important;
    height: 0!important;
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
    background: none;
    border: none!important;
  }

  // 미노출 영역
  .headerWrap, .topButtonWrapper, .guideBannerWrapper, .infoCover {
    width: 0;
    height: 0;
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
    background: none;
    border: none;
  }

  /**
    * 고정 헤더 푸터 영역
   */

  .printHeader{
    display: table-header-group!important;
  }

  // 프린트 헤더 여유공간 확보
  .printHeaderSpace{
    display: block!important;
    height: 147px!important;
    //margin: 0 auto!important;
  }

  .printFooterSpace{
    display: table-footer-group!important;
    margin-bottom: 0!important;
    margin-top: auto!important;
  }

  .printFooter {
    display: block!important;
    height: 60px!important;
    margin: 40px auto 0!important;
    border-top: 4px solid #76718e!important;
    page-break-after: avoid!important;
    background-color: white!important;
  }

  /**
     검사결과지
     고정헤더, 푸터
   */
  .fixedHeaderWithInfo {
    display: table-header-group !important;
    page-break-before: always!important;
    background-color: white !important;
    width: 1020px;
    height: 107px;
    position: fixed !important;
    top: 10px;
  }

  // 프린트 시 기존 푸터 선 미노출
  .contentFooter{
    display: none!important;
  }

  .page .fixedHeaderWithLine {
    display: block;
  }

  .pageWrapper .page{
    border: none!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
    padding-top: 0!important;
    padding-bottom: 0!important;
    page-break-before: always!important;
    border-radius: 0!important;
  }


  .guidePrintHeader{
    display: table-header-group!important;
  }

  /**
     학습진로가이드
     고정헤더, 푸터
   */
  .fixedHeaderWithLine {
    position: fixed!important;
    left: 70px!important;
    top: 20px!important;
    width: 1020px!important;
    background-color: white;
  }

  .guidePrintHeaderSpace{
    display: block!important;
    height: 47px!important;
    margin-bottom: 47px;
  }

  .guidePrintFooterSpace{
    display: table-footer-group!important;
    margin-bottom: 0!important;
    margin-top: auto!important;
  }

  .guidePrintFooter {
    display: block!important;
    height: 60px!important;
    margin: 40px auto 0!important;
    border-top: 4px solid #76718e!important;
    page-break-after: avoid!important;
    background-color: white!important;
  }
}


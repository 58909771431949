$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@import "../print";

.first {
  box-sizing: border-box;
  width: 1160px;
  height: 1642px;
  margin: 50px auto 0;
  border: 2px solid #E5E5EF;
  border-radius: 20px;
  padding: 50px 70px;
  position: relative;
  background-color: #383649;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .pageCoverHeader {
    padding-bottom: 8px;
    border-bottom: 4px solid #76718E;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .textWrap {
      color: #CDCBDE;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: -0.54px;
    }

    .kpassIcon {
      width: 114px;
    }
  }

  .pageBody {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 50px;

    .pageBodyContent {
      display: flex;
      flex-direction: column;
      gap: 100px;

      .kpassLogo {
        width: 337px;
        height: 154px;
      }

      .guideTitle {
        font-size: 85px;
        line-height: 1.3;
        letter-spacing: -2.55px;
        color: #A3A0BA;
        position: relative;
        z-index: 1;

        > .bold {
          color: #FFFFFF !important;
          display: inline-block;
        }

        > .light {
          color: #FFFFFF;
          font-size: 65px;
          font-weight: normal;
          line-height: 1.3;
          display: inline-block;

          &.balance {
            font-size: 55px;

            &.notKO {
              font-size: 65px;
            }
          }
        }

        > .map {
          display: block;

        }

        .small {
          font-size: 50px;
          letter-spacing: -1.65px;
        }
      }

      .writerWrap {
        display: flex;

        .title {
          padding: 0 30px;
          background: #FFFFFF;
          border-radius: 100px 100px 0 100px;
          font-size: 22px;
          font-weight: bold;
          letter-spacing: -0.03em;
          color: #383649;
          height: 75px;
          line-height: 75px;
          z-index: 2;
        }

        .name {
          border: 1px solid #76718E;
          border-left-width: 0;
          border-radius: 0 100px 100px 0;
          font-size: 22px;
          letter-spacing: -0.03em;
          color: #FFFFFF;
          padding: 20px 40px 20px 60px;
          margin-left: -35px;
          box-sizing: border-box;
          height: 75px;
          line-height: 1.6;
          z-index: 1;

          &.notKO {
            width: 600px;
            border-radius: 40px;
            padding: 20px 40px;
            margin-left: 10px;
            height: auto;
            border-left-width: 1px;
          }
        }
      }
    }



    .testerProfile {
      //margin-top: 267px;
      height: auto;

      .row {
        display: flex;
        height: 95px;
        box-sizing: border-box;

        border-top: 1px solid #76718E;
        border-bottom: 1px solid #76718E;

        font-size: 22px;
        letter-spacing: -0.03em;
        color: #FFFFFF;

        .title {
          display: flex;
          text-align: center;
          width: 170px;
          height: 100%;
          background-color: #4B485E;

          font-weight: bold;
        }

        .content {
          display: flex;
          text-align: left;
          width: 290px;
          height: 100%;
          padding-left: 30px;
        }

        span:first-child {
          border-right: 1px solid #76718E;
        }

        span:nth-child(3) {
          border-right: 1px solid #76718E;
          border-left: 1px solid #76718E;
        }

        > span {
          display: flex;
          align-items: center;

          &.title {
            justify-content: center;
          }
        }
      }
    }
  }

  .pageCoverFooter {
    padding-top: 7px;
    border-top: 4px solid #76718e;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 18px;
      line-height: 160%;
      color: #CDCBDE;
    }

    img {
      width: 133px;
      height: 40px;
    }
    .kpassLogo {
      width: 337px;
      height: 154px;
    }
  }

  .background1 {
    position: absolute;
    top: 150px;
    right: 0;
  }

  .background2 {
    position: absolute;
    top: 783px;
    left: 0;
  }

  &.above12 {
    .pageBody {

      .pageBodyContent {

        .writerWrap {

          .name {
            &.notKO {
              width: fit-content;
            }
          }
        }
      }
    }
  }
}
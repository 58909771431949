$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@import "test_result/print";

.topButtonWrapper {
  position: fixed;
  right: 200px;
  bottom: 70px;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  opacity: 0;


  &.show {
    opacity: 1;
  }

  .topButton {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;

    &.mobile {
      width: 150px;
      height: 150px;

      > .img {
        width: 90px;
        height: 90px;
      }

      //@include mobile {
      //  $unit: 0.4;
      //  width: #{15 * $unit}vmin;
      //  height: #{15 * $unit}vmin;
      //
      //  img {
      //    width: #{9 * $unit}vmin;
      //    height: #{9 * $unit}vmin;
      //  }
      //}
    }
  }

  .guideLink {
    height: 187px;
    width: 195px;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.hide {
      display: none;
    }

    .linkey {
      width: 187px;
      height: 187px;
      position: absolute;
      bottom: 0;
      left: 4px;
    }

    @mixin guideLinkButtonStyle {
      border-radius: 100px;
    }

    &.hover {
      > .guideLinkButton {
        &:hover {
          > .hover-bg {
            opacity: 1;
          }
        }
      }
    }

    > .guideLinkButton {
      @include guideLinkButtonStyle;
      position: relative;
      padding: 16px 34px;
      background: linear-gradient(170deg, #5438D7 15.6%, #38258F 92.93%);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.30);
      border: none;
      cursor: pointer;
      width: 100%;
      box-sizing: border-box;

      font-size: 20px;
      font-weight: normal;
      line-height: 1.3;
      letter-spacing: -0.6px;
      color: white;

      > .hover-bg {
        @include guideLinkButtonStyle;
        background: linear-gradient(170deg, #E60063 15.6%, #A22446 92.93%);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.5s ease-in-out;
      }

      > .text {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        > .strong {
          font-weight: bold;

          &.en {
            font-size: 22px;
          }
        }
      }
    }

    $multipleNumber: 2.3;
  }
}
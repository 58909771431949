$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@import "../../variables.scss";
@import "../print";

.page {
  page-break-before: always;
  box-sizing: border-box;
  width: 1160px;
  margin: 50px auto 0;
  border: 2px solid $kpNavy100;
  border-radius: 20px;
  padding: 50px 70px;
  background-color: white;
  position: relative;
  overflow: hidden;

  .characterBg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    > img {
      left: 1553px;
    }
  }
}

.contentFooter {
  width: 100%;
  border: none;
  border-top: 4px solid $c76;
}

.pageBody {
  display: flex;
  flex-direction: column;
  gap: 120px;

  position: relative;
  z-index: 2;
  padding: 40px 30px;

  &.narrow-gap {
    gap: 50px;
  }

  &.aboutCharacteristics {
    padding: 80px 30px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 80px;

    > .characterSummary {
      display: flex;
      gap: 36px;
      align-items: center;

      > .left {
        display: flex;
        flex-direction: column;
        gap: 60px;

        > .titleWrapper {
          display: flex;
          flex-direction: column;
          gap: 26px;

          // 나는 어떤 유형의 사람일까?
          > .whatType {
            padding: 10px 40px;
            background: white;
            border-radius: 200px;
            font-size: 28px;
            font-weight: normal;
            line-height: 1.6;
            letter-spacing: -0.84px;
            color: $kpNavy400;

            position: relative;
            width: fit-content;

            .typeBold {
              font-weight: bold;
              color: $kpNavy400;
            }

            > .bubbleTail {
              position: absolute;
              left: 321px;
              bottom: -16px;
            }
          }

          > .title {
            > span {
              font-size: 65px;
              font-weight: bold;
              line-height: 1.3;
              letter-spacing: -0.03em;
              color: $kpBlue600;
              position: relative;
              z-index: 2;

              > .realText {
                position: relative;
                z-index: 3;
              }

              &:before {
                text-shadow: 5px 6px 11px rgba(165, 160, 190, 0.9);
                content: attr(data-text);
                -webkit-text-stroke: 8px white;
                color: pink;
                position: absolute;
                left: 0;
                z-index: -1;
              }

              >.light {
                font-weight: normal;
                z-index: 3;
              }
              &.light {
                font-weight: normal;
              }

              &.font85 {
                font-size: 85px;
              }
            }

            &.notKO {
              > span {
                line-height: 1.1;
              }
            }
          }
        }

        > .paragraphWrapper {
          font-size: 22px;
          font-weight: normal;
          line-height: 1.6;
          letter-spacing: -0.66px;
          color: #6b6b6b;

          display: flex;
          flex-direction: column;
          gap: 30px;

          .deepBold {
            font-weight: bold;
            color: $kpNavy500Main !important;
          }

          > .tags {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            > li {
              padding: 10px 24px;
              background-color: $kpBlue000Bg;
              border-radius: 100px;

              display: flex;
              gap: 4px;
              align-items: center;

              font-size: 22px;
              font-weight: normal;
              line-height: 1.6;
              letter-spacing: -0.66px;
              color: $kpNavy500Main;

              &:before {
                content: '#';
                color: $kpBlue500Main;
                font-weight: bold;
              }
            }
          }
        }
      }

      > .rightImage {
        width: 460px;
        height: 700px;
        flex-shrink: 0;

        > img {
          width: 460px;
        }
      }
    }

    > .abilities {
      display: flex;
      flex-direction: column;
      gap: 80px;

      > li {
        display: flex;
        gap: 50px;
        align-items: flex-start;

        > .title {
          width: 160px;
          flex-shrink: 0;
          box-sizing: border-box;

          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;

          padding: 30px 16px;
          background-color: $kpBlue600;
          border-radius: 10px 10px 100px 100px;
          text-align: center;

          font-size: 28px;
          font-weight: bold;
          line-height: 1.3;
          letter-spacing: -0.84px;
          color: white;

          > .symbol {
            width: 100px;
          }
        }

        > .advantages {
          display: flex;
          flex-direction: column;
          gap: 20px;

          > li {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: flex-start;

            > .title {
              background-color: $kpBlue000Bg;
              padding: 0 12px;
              border-radius: 6px;

              font-size: 22px;
              font-weight: bold;
              line-height: 1.6;
              letter-spacing: -0.66px;
              color: $kpBlue600;
            }

            > .description {
              font-size: 22px;
              font-weight: normal;
              line-height: 1.6;
              letter-spacing: -0.66px;
              color: $g500Subtext;
            }

            > .descriptionList {
              color: $g500Subtext;
              font-size: 22px;
              font-weight: normal;
              line-height: 160%;
              letter-spacing: -0.66px;
              list-style-type: disc;
              margin-left: 1.3em;

              > li {
                > .title {
                  font-weight: bold;
                  float: left;

                  &:after {
                    content: '\00a0\00a0|\00a0\00a0';
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.underline {
  display: inline-block;
  width: 100%;
  height: 11px;
  border: none;
  background-image: url('https://images.feel-good.io/guide/underline.svg');
}
$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@import '../variables';
@import "print";
// 자주 사용되는 스타일들 정의 (헤더, 푸터 등 ..)
// 검사결과 안내지 헤더 구문 (라인이 들어간 헤더)
.headerWithLine {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 4px solid #76718e;
  padding-bottom: 8px;
  position: relative;
  z-index: 2;

  .textWrapper{
    color: #76718E;
    font-size: 18px;
    line-height: 160%;
  }

  .url {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: -0.54px;
    color: #76718e;
  }

  .secondaryLogo {
    width: 114px;
  }

  > .headerAfter {
    display: none;
  }
}
.fixedHeaderWithLine {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 4px solid #76718e;
  padding-bottom: 8px;
  position: relative;
  z-index: 2;

  .textWrapper{
    color: #76718E;
    font-size: 18px;
    line-height: 160%;
  }

  .url {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: -0.54px;
    color: #76718e;
  }

  .secondaryLogo {
    width: 114px;
  }

  > .headerAfter {
    display: none;
  }
}

// 검사결과 안내지 푸터 구문 (라인이 들어간 푸터)
.footerWithLine {
  display: block;
  //position: fixed;
  //top: 0;
  width: 100%;
  border-top: 4px solid #76718e;
  padding-top: 8px;
  height: 70px;

  .footerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .copyright {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.4;
    letter-spacing: -0.54px;
    color: #76718e;
  }

  .logo {
    width: 133px;
  }
}

// 검사결과 보고서 헤더 구문 (정보가 포함된 헤더)
//.headerWithInfo{
.headerWithInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;

  > .header1 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .date {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: -0.54px;
      color: $c76;
    }

    > .secondary-logo {
      width: 114px;
    }
  }

  > .header2 {
    padding: 12px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: $cF6;

    font-size: 22px;
    font-weight: normal;
    line-height: 1.6;
    letter-spacing: -0.66px;

    .strong {
      font-weight: bold;
    }

    > .profile {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}


.fixedHeaderWithInfo {
  display:block;
  background-color: white;
  width: 1020px;
  height: 107px;
  position: relative;
  //top: 0;


  flex-direction: column;
  //position: fixed;
  //display: none;
  //position: fixed;
  //top: 10px;

  > .header1 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .date {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: -0.54px;
      color: $c76;
    }

    > .secondary-logo {
      width: 114px;
    }
  }

  > .header2 {
    padding: 12px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: $cF6;

    font-size: 22px;
    font-weight: normal;
    line-height: 1.6;
    letter-spacing: -0.66px;

    .strong {
      font-weight: bold;
    }

    > .profile {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}

$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  //min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@import "print";

/* 딤처리된 배경 */
.modal {
  display: block; /* 초기에는 모달이 보이지 않음 */
  position: fixed;
  z-index: 11; /* 모달이 다른 컨텐츠 위에 보이도록 함 */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* 필요시 스크롤 가능 */
  background-color: rgba(0, 0, 0, 0.4); /* 딤처리된 배경색 */
}

/* 모달 콘텐츠 */
.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 1020px;
  box-sizing: border-box;

  transform: translate(-50%, -50%); /* 콘텐츠를 정확히 화면 중앙에 위치시킴 */
  background-color: white;
  padding: 40px;
  border: 1px solid #888;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* 그림자 효과 */
  font-family: Arial, sans-serif; /* 폰트 설정 */

  border-radius: 50px;
  background: linear-gradient(90deg, #535162 0%, #1b1a2c 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/* 닫기 버튼 */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* 모달 본문 */
.modalBody h3 {
  font-size: 18px;
  margin-top: 10px;
}

.modalBody p {
  font-size: 14px;
  line-height: 1.6;
  margin: 10px 0;
}

#openModalBtn {
  margin: 20px;
  padding: 10px 20px;
  font-size: 16px;
}

.modalInnerContent {
  border-radius: 10px;
  background-color: white;
  padding: 20px;

  max-height: 60vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.modalContentTitle {
  color: var(--K-PASS-KPnavy-000_bg, #fbfbff);

  /* Title/ST_Bold */
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 44.8px */
  letter-spacing: -0.84px;
  margin-bottom: 20px;
}

.modalTopDesc {
  display: flex;
  justify-content: space-between;
}

.innerContentTitle {
  color: var(--GrayScale-G-700_bodytext, #404040);

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 35.2px */
  letter-spacing: -0.66px;
}

.innerContentList {
  color: var(--GrayScale-G-500_subtext, #6b6b6b);

  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: -0.66px;

  list-style: disc;
  padding-left: 29px;

  li {
    list-style-type: disc;
  }

  > .strong {

  }
}

.semiBold {
  font-weight: 700;
}

.innerContentSubitem {
  color: var(--GrayScale-G-700_bodytext, #404040);

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.54px;
  padding-left: 10px;
}

.firstSubitem {
  margin-top: 15px;
}

.secondTitle {
  margin-top: 50px;
}

.problemSolveContainer {
  display: flex;
}

.problem_solving_image {
  width: 800px;
  height: 210.526px;
  margin: 40px auto;
}

.innerContentMainDesc {
  color: var(--GrayScale-G-700_bodytext, #404040);

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 35.2px */
  letter-spacing: -0.66px;
}

.innerContentBottomMargin1 {
  margin-bottom: 40px;
}

.innerContentBottomMargin2 {
  margin-bottom: 20px;
}

.innerContentBottomMargin3 {
  margin-bottom: 15px;
}

.brainContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 15px;

  position: relative;
}

.brainContentLeftContainer,
.brainContentRightContainer {
  width: 410px; /* 왼쪽 및 오른쪽 컨테이너 너비 설정 */
  height: 680px; /* 왼쪽 및 오른쪽 컨테이너 높이 설정 */
  padding: 0 30px; /* 내부 여백 설정 */
  display: flex; /* 내부 텍스트 정렬을 위한 플렉스 레이아웃 설정 */
  flex-direction: column; /* 세로 방향으로 정렬 */
  justify-content: center; /* 세로 방향으로 중앙 정렬 */
  box-sizing: border-box; /* 박스 모델의 크기 조정 */
  border-radius: 0 30px; /* 모서리 둥글기 설정 */
  background: rgba(255, 241, 242, 0.7); /* 배경 색상 설정 */
  text-align: left; /* 텍스트 정렬 */
  font-size: 18px; /* 폰트 크기 설정 */
  font-weight: 400; /* 폰트 두께 설정 */
  line-height: 250%; /* 줄 높이 설정 */
  letter-spacing: -0.54px; /* 문자 간격 설정 */
}

.brainContentLeftContainer {
  color: #5c3439;
}

/* 오른쪽 컨테이너 스타일 설정 */
.brainContentRightContainer {
  background: rgba(236, 245, 255, 0.7); /* 배경 색상 설정 */
  text-align: right; /* 오른쪽 텍스트 정렬 */
  color: var(--FeelGood-FGblue-700, #1c467a); /* 텍스트 색상 설정 */
}

/* 이미지 스타일 설정 */
.brainImg {
  width: 469px; /* 이미지 너비 설정 */
  height: auto; /* 이미지 높이를 자동으로 설정하여 비율 유지 */
  position: absolute; /* 이미지 위치 설정 */
  right: 24%; /* 오른쪽 끝으로 정렬 */
  top: 50%; /* 수직 중앙으로 정렬 */
  transform: translateY(-50%); /* 수직 중앙 정렬 보정 */
  z-index: 1; /* 이미지가 내용 위에 위치하도록 설정 */
}


.leftbrainImg,
.rightbrainImg {
  display: none;
}
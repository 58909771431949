$kpNavy500Main: #383649;
$g500Subtext: #6B6B6B;
$kpBlue600: #462FB5;
$kpBlue700: #352388;
$gWhite: #FFFFFF;
$kpNavy400: #76718E;
$kpNavy100: #E5E5EF;
$kpBlue500Main: #583BE2;
$kpBlue000Bg: #F6F3FF;
$kpNavy200: #CDCBDE;
$kpNavy300: #A3A0BA;
$kpNavy000Bg: #FBFBFF;
$kpNavy700: #22202C;
$orange500main: #F4A418;
$c4B485E: #4B485E;
$c76718E: #76718E;
$c76718E30: #76718E30;
$c76718E20: #76718E20;
$c76: #76718E;
$c75: #757575;
$cFB: #FBFBFF;
$cF6: #F6F6F6;
$cE5: #E5E5EF;
$cF1: #F1EEFF;
$c38: #383649;
$cA3: #A3A0BA;
$cF1: #F1EEFF;
$cE5: #E5E5EF;
$cF6F3: #F6F3FF;
$c22: #22202C;
$cF4: #F4A418;
$cCD: #CDCBDE;
$c6B: #6B6B6B;
$cE6: #E60063;
$c77: #7762DF;
$c58: #583BE2;
$darkmongBoxTransitionValue: all 0.5s ease-in-out;
$breakpoint-mobile: 768px;

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$breakpoint-mobile + 1px}) {
    @content;
  }
}